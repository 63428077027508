.width-12-rem {
  max-width: 12rem;
}

.width-8-rem {
  max-width: 8rem;
}

.container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.flex-basis-width-auto {
  flex: 0 1 auto;
  width: auto;
}
